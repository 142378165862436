import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import FeaturedImg from "../components/featuredImg";
import ImgWithOrient from "../components/imageOrient";
import { css } from "@emotion/core";
import Helmet from "react-helmet";
import SEO from "../components/SEO";

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Tauranga Wedding Venue With Accommodation Lazy River Lodge</title>
    </Helmet>

    <SEO pagePath={"tauranga-wedding-venue-lazy-river-lodge"} pageSEO />

    <article>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <FeaturedImg
          alt="Tauranga wedding venue"
          fluid={data.file.childImageSharp.fluid}
        />

        <div
          css={css`
            min-height: 45vh;
            margin: 30vw 0 15vw 0;
            padding: 6.5vw 9.333vw 0 9.333vw;
            @media (min-width: 768px) {
              display: flex;
              flex-direction: column;
              width: calc(60% - 2vw);
              margin: 20vw 0;
              padding: 0 9.333vw;
              position: relative;
            }
          `}
        >
          <Header
            title="Tauranga Wedding Venue"
            description="Lazy River Lodge is home to my floristy studio. Located in the Bay of Plenty and with 9 acres of grounds it's the perfect wedding venue location to forage for my creations."
          />
        </div>
      </div>

      <div
        css={css`
          .image {
            margin: 9.375vw 0;
          }
          @media (min-width: 768px) {
            max-width: 915px;
            margin: 12.5vw auto;
            padding: 0 8vw;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .image {
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin: 0 10px;

              &:not(:first-of-type) {
                margin: 9.375vw 10px 0 10px;
              }
            }

            .image.portrait {
              flex-basis: calc(50% - 20px);
            }

            .image.landscape {
              flex-basis: calc(100% - 20px);
            }

            .image.square {
              flex-basis: calc(60% - 40px);
              margin-left: 25%;
            }
          }
        `}
      >
        {data.allFile.edges.map(({ node }, i) => (
          <ImgWithOrient
            key={i}
            className="image"
            aspectRatio={node.childImageSharp.fluid.aspectRatio}
            alt={node.name}
            fluid={node.childImageSharp.fluid}
            offset={i}
          />
        ))}
      </div>

      <div
        css={css`
          min-height: 45vh;
          padding: 0 9.333vw;
          clear: both;
          max-width: 600px;
        `}
      >
        <h2
          css={css`
            text-transform: uppercase;
            font-weight: 600;
          `}
        >
          Lazy River Lodge
        </h2>
        <div>
          <p>
            One of the best kept secrets is a little known{" "}
            <a
              href="https://lazyriverlodge.com"
              title="Tauranga wedding venues with accommodation"
            >
              Tauranga wedding venue with accommodation
            </a>{" "}
            set in the countryside surrounded by new and established specimen
            trees called Lazy River Lodge. The main lodge building is over
            2000sqm and has plenty of indoor areas.
          </p>
          <p>
            The Atrium is a beautiful indoor ceremony location, attached to the
            main lodge building this space can be used for your ceremony if the
            weather is wet outside and it can also double as a dance hall for
            later in the evening.
          </p>
          <p>
            The dining hall has seating for approximately 120 guests. This can
            be extended by opening all the french doors out onto the terrace
            which overlooks the lazy river which flows through the property
            spilling over into several ponds dotted with water lilies, as it
            makes its way towards the sea.
          </p>
          <p>
            The lodge is perfect for a natural{" "}
            <Link to="/lazy-river-lodge-autumn-elopement">forest wedding</Link>{" "}
            with multiple outdoor ceremony spots nestled under large trees and
            backup wet weather options.
          </p>
          <p>
            Email me if you'd like to come for a visit{" "}
            <a href="mailto:hello@rosieswildflowerco.com">
              hello@rosieswildflowerco.com
            </a>
          </p>
        </div>
      </div>
    </article>
  </Layout>
);

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: "venue" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    file(
      relativePath: {
        eq: "venue/marionhweddings-lazyriverlodge-bay-of-plenty-wedding-venue-NZ-autumn-elopement-WEB-148.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 915, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
